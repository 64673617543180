import { forwardRef } from 'react';
import { tv, VariantProps } from 'tailwind-variants';

import { cn } from './ui.helpers';

const buttonVariants = tv({
  base: 'inline-flex items-center justify-center gap-2 whitespace-nowrap rounded-lg text-sm font-medium leading-normal transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 [&_svg]:pointer-events-none [&_svg]:size-4 [&_svg]:shrink-0',

  variants: {
    variant: {
      primary: 'bg-gray-800 text-white enabled:hover:bg-gray-900',
      secondary: 'bg-zinc-200 text-zinc-800 enabled:hover:bg-zinc-300',
      destructive: 'bg-red-500 text-white enabled:hover:bg-red-600',
      ghost: 'text-zinc-500 enabled:hover:bg-zinc-100',
      outline: 'border border-gray-200 text-gray-800 enabled:hover:bg-gray-100',
    },

    size: {
      sm: 'h-9 rounded-md px-3',
      md: 'h-10 px-4 py-2',
      lg: 'h-11 px-6 py-4',
      icon: 'h-10 w-10',
    },
  },

  defaultVariants: {
    variant: 'primary',
    size: 'md',
  },
});

interface Props
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof buttonVariants> {
  children: React.ReactNode;
}

const Button = forwardRef<HTMLButtonElement, Props>(
  ({ className, size, variant, ...props }, forwardedRef) => {
    return (
      <button
        className={cn(buttonVariants({ size, variant }), className)}
        ref={forwardedRef}
        {...props}
      />
    );
  }
);

export default Button;
