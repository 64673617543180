import {
  ApolloClient,
  ApolloProvider,
  createHttpLink,
  InMemoryCache,
  split,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { getMainDefinition } from '@apollo/client/utilities';
import { useAuth } from '@workos-inc/authkit-react';
import { createClient } from 'graphql-ws';

const cache = new InMemoryCache();

const httpLink = createHttpLink({ uri: '/graphql' });

const wsUrl =
  window.location.hostname === 'localhost'
    ? 'ws://localhost:3000/graphql'
    : `wss://${window.location.host}/graphql`;

export default function ApolloProviderWithClient({
  children,
}: {
  children: React.ReactNode;
}) {
  const { getAccessToken } = useAuth();

  const getAuthorizationHeader = async () => {
    try {
      const accessToken = await getAccessToken();
      return `Bearer ${accessToken}`;
    } catch (error) {
      console.error(error);
      return null;
    }
  };

  const wsLink = new GraphQLWsLink(
    createClient({
      url: wsUrl,
      connectionParams: async () => ({
        authorization: getAuthorizationHeader(),
      }),
    })
  );

  const authLink = setContext(async (_, { headers }) => {
    return {
      headers: {
        ...headers,
        authorization: await getAuthorizationHeader(),
      },
    };
  });

  const splitLink = split(
    ({ query }) => {
      const definition = getMainDefinition(query);
      return (
        definition.kind === 'OperationDefinition' &&
        definition.operation === 'subscription'
      );
    },
    wsLink,
    httpLink
  );

  const client = new ApolloClient({
    cache,
    link: authLink.concat(splitLink),
  });

  return <ApolloProvider client={client}>{children}</ApolloProvider>;
}
