import { gql } from '../__generated__/gql';

export const LIST_QUESTIONS = gql(/* GraphQL */ `
  query ListQuestions(
    $after: Cursor
    $before: Cursor
    $filter: QuestionListFilterInput
    $first: Int
    $last: Int
    $orderBy: QuestionOrder
  ) {
    questions(
      after: $after
      before: $before
      filter: $filter
      first: $first
      last: $last
      orderBy: $orderBy
    ) {
      items {
        id
        isActive
        lastResponseDate
        primaryQuestion
        ratings {
          id
          label
          value
        }
        tags {
          id
          name
          color
        }
        totalInsights
      }
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
        total
      }
    }
  }
`);
