import { faTriangleExclamation } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { zodResolver } from '@hookform/resolvers/zod';
import { useAuth } from '@workos-inc/authkit-react';
import { RefObject, useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Navigate } from 'react-router-dom';

import { cn } from '@/shared-ui';
import {
  COMPANY_INFO_STORAGE_KEY,
  useOnboardingCompanyInfo,
} from './manager-onboarding-company-info.hooks';
import { ManagerOnboardingComponentInfoButtonGroup } from './manager-onboarding-component-info-button-group';
import { ManagerOnboardingComponentInfoInput } from './manager-onboarding-component-info-input';
import { ManagerOnboardingLayout } from './manager-onboarding-layout';
import { useOnboarding } from './manager-onboarding.hooks';
import {
  companyInfoFormSchema,
  CompanyInfoFormValues,
} from './manger-onboarding.helpers';

const inputFields = [
  { name: 'orgName', label: 'Company/Brand/Organization' },
  { name: 'website', label: 'Website' },
  { name: 'jobTitle', label: 'Your job title' },
] as const;

const numberOfPeopleOptions = ['1', '2-99', '100-1499', '1500-1999', '2000+'];

const tryingForOptions = [
  'Deeper insights',
  'Better response rates',
  'Increased sales',
  'Save $ / Replace tools',
  'AI survey analysis',
];

export default function OnboardingCompanyInfo() {
  const errorElementRef: RefObject<HTMLDivElement> = useRef(null);
  const {
    handleSubmit,
    register,
    setFocus,
    setValue,
    watch,
    clearErrors,
    formState: { errors, isValid, touchedFields },
    trigger,
    reset,
  } = useForm<CompanyInfoFormValues>({
    resolver: zodResolver(companyInfoFormSchema),
    mode: 'onTouched',
  });
  const { isInvalidWebsiteError, isSubmitting, onSubmit, onClear } =
    useOnboardingCompanyInfo();
  const { user } = useAuth();
  const [showErrors, setShowErrors] = useState(false);
  const { isLoading: isCompanyInfoLoading, companyInfo } =
    useOnboarding(isSubmitting);

  useEffect(() => {
    const storedFormData = localStorage.getItem(COMPANY_INFO_STORAGE_KEY);
    if (storedFormData) {
      const parsedData = JSON.parse(storedFormData);
      reset(parsedData);
    }
    setFocus('orgName');
  }, [setFocus, reset]);

  const saveToLocalStorage = (data: Partial<CompanyInfoFormValues>) => {
    const currentData = localStorage.getItem(COMPANY_INFO_STORAGE_KEY);
    const parsedData = currentData ? JSON.parse(currentData) : {};
    const updatedData = { ...parsedData, ...data };
    localStorage.setItem(COMPANY_INFO_STORAGE_KEY, JSON.stringify(updatedData));
  };

  useEffect(() => {
    if (isInvalidWebsiteError && errorElementRef.current) {
      errorElementRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    }
  }, [isInvalidWebsiteError]);

  const allFieldsFilled =
    Object.keys(watch()).length ===
    Object.keys(companyInfoFormSchema.shape).length;

  if (!isCompanyInfoLoading && companyInfo) {
    onClear();
    return <Navigate to="/home/onboarding/questions" />;
  }
  if (isSubmitting) {
    return <Navigate to="/home/onboarding/company-info-submitting" />;
  }

  return (
    <ManagerOnboardingLayout
      title={`Hi ${
        user?.firstName || 'there'
      }, I'm Evo, an AI assistant. Enter the details below
        to help me learn more about how you plan to use TheySaid.`}
    >
      {isInvalidWebsiteError && (
        <div
          className="flex flex-col items-center justify-center gap-2 pb-3"
          ref={errorElementRef}
        >
          <FontAwesomeIcon
            icon={faTriangleExclamation}
            className="m-auto text-center text-6xl text-yellow-500"
          />
          <h3 className="font-display text-center text-2xl font-semibold leading-tight text-zinc-800">
            Oops! We had some trouble with that domain.
          </h3>
          <div className="text-center leading-normal text-zinc-500">
            Please copy + paste the entire URL from another window with your
            website open.
          </div>
        </div>
      )}
      <form
        onSubmit={handleSubmit(
          (data) => {
            setShowErrors(true);
            if (isValid) {
              onSubmit(data);
            }
          },
          () => setShowErrors(true)
        )}
        className="pm-5 relative flex flex-col gap-5 border-b border-t py-3"
      >
        <div className="flex flex-col gap-5 p-6 pt-3">
          {inputFields.map(({ name, label }) => (
            <ManagerOnboardingComponentInfoInput
              key={name}
              name={name}
              label={label}
              register={register}
              watch={watch}
              trigger={trigger}
              errors={errors}
              touchedFields={touchedFields}
              showErrors={showErrors}
              isSubmitting={isSubmitting}
              isCompanyInfoLoading={isCompanyInfoLoading}
              saveToLocalStorage={saveToLocalStorage}
            />
          ))}
          <ManagerOnboardingComponentInfoButtonGroup
            options={numberOfPeopleOptions}
            fieldName="numberOfPeople"
            label="How many people work at your company?"
            watch={watch}
            setValue={setValue}
            clearErrors={clearErrors}
            errors={errors}
            showErrors={showErrors}
            setShowErrors={setShowErrors}
            isSubmitting={isSubmitting}
            isCompanyInfoLoading={isCompanyInfoLoading}
            saveToLocalStorage={saveToLocalStorage}
          />
          <ManagerOnboardingComponentInfoButtonGroup
            options={tryingForOptions}
            fieldName="tryingFor"
            label="Why are you trying out TheySaid?"
            watch={watch}
            setValue={setValue}
            clearErrors={clearErrors}
            errors={errors}
            showErrors={showErrors}
            setShowErrors={setShowErrors}
            isSubmitting={isSubmitting}
            isCompanyInfoLoading={isCompanyInfoLoading}
            saveToLocalStorage={saveToLocalStorage}
          />
        </div>
        <div className="w-full border-t">
          <div className="p-6">
            <button
              className={cn(
                'inline-flex h-14 w-full items-center justify-center rounded-lg bg-gray-800 px-4 py-2 text-base font-normal leading-normal text-white transition-opacity duration-200 ease-in-out',
                (!allFieldsFilled || isSubmitting) &&
                  'cursor-not-allowed opacity-50'
              )}
              disabled={
                !allFieldsFilled || isSubmitting || isCompanyInfoLoading
              }
              onClick={() => setShowErrors(true)}
            >
              {(isSubmitting || isCompanyInfoLoading) && (
                <div className="mr-2 h-5 w-5 animate-spin rounded-full border-b-2 border-white" />
              )}
              Continue
            </button>
          </div>
        </div>
      </form>
    </ManagerOnboardingLayout>
  );
}
