import { gql } from '../__generated__/gql';

export const GET_UNREAD_INSIGHTS = gql(/* GraphQL */ `
  query GetUnreadInsights {
    questions {
      items {
        id
        totalUnreadInsights
      }
    }
  }
`);
