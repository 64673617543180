import { gql } from '../__generated__/gql';

export const GET_QUESTION_WITH_STATS = gql(/* GraphQL */ `
  query GetQuestionWithStats($id: UUID!) {
    question(id: $id) {
      id
      primaryQuestion
      avgScore
      avgMessageDepth
      totalInsights
      totalGrowthSignals
      totalRiskSignals
      totalUnreadInsights
      tags {
        id
        name
      }
    }
  }
`);
