import {
  DateRangePicker,
  PageHeader,
  PageHeaderNav,
  StateMessageError,
  StateMessageLoading,
  Summary,
} from '@/shared-ui';
import DashboardEmptyState from './dashboard-empty-state';
import DashboardRatings from './dashboard-ratings';
import DashboardStatistics from './dashboard-statistics';
import { useDashboard, useDashboardFilter } from './dashboard.hooks';

export default function Dashboard() {
  const {
    dashboard,
    error,
    loadingDashboard,
    loadingSummary,
    onGenerateSummary,
  } = useDashboard();

  const [filter, setFilter] = useDashboardFilter();

  return (
    <div className="w-full bg-zinc-50">
      <PageHeader title="Home">
        <PageHeaderNav
          items={[
            { name: 'Dashboard', to: '/home/dashboard' },
            { name: 'Company Summary', to: '/home/company-summary' },
          ]}
        />
      </PageHeader>

      <main className="space-y-6 px-10 py-6">
        <div className="flex justify-end">
          <DateRangePicker
            startDate={filter.since}
            endDate={filter.until}
            onChange={(since, until) => setFilter({ since, until })}
          />
        </div>
        {loadingDashboard ? (
          <StateMessageLoading />
        ) : error ? (
          <StateMessageError message={error} />
        ) : !dashboard || !dashboard.totalInsights ? (
          <DashboardEmptyState />
        ) : (
          <>
            <DashboardStatistics {...dashboard} />

            <Summary
              loading={loadingSummary}
              summary={dashboard.summary}
              onGenerate={onGenerateSummary}
            />

            <DashboardRatings
              ratingDistribution={dashboard.ratingDistribution}
            />
          </>
        )}
      </main>
    </div>
  );
}
