import { gql } from '../__generated__/gql';

export const GET_SURVEY_ORGANIZATION_SETTINGS = gql(/* GraphQL */ `
  query GetSurveyOrganizationSettings($surveyId: UUID!) {
    surveyOrgSettings(surveyId: $surveyId) {
      avatarUrl
      logoUrl
      brandColor
      hideTheysaidLogo
    }
  }
`);
