import { SurveyChat, SurveyPoweredBy, SurveyRatings } from '@/survey-response';
import { ComponentProps, useState } from 'react';
import ManagerBrandingPreviewSection from './manager-branding-preview-section';
import { SAMPLE_MESSAGES, SAMPLE_SURVEY } from './manager-branding.helpers';

export default function ManagerBrandingMobilePreview({
  accentColor,
  logoUrl,
  avatarUrl,
  showPoweredBy,
}: ComponentProps<typeof ManagerBrandingPreviewSection>) {
  const [selectedRating, setSelectedRating] = useState<number | null>(2);

  return (
    <div className="animate-fade-in flex h-[40rem] flex-col items-center justify-center gap-0 rounded-xl bg-gray-100 p-6">
      <div className="relative mx-auto flex min-h-[58rem] w-full max-w-[27rem] origin-center scale-[0.6] flex-col items-center gap-4 self-stretch overflow-hidden overflow-y-auto rounded-3xl border-4 border-zinc-300 bg-gray-100 pb-4">
        <img
          src="/img/mobile-browser-header.svg"
          alt="Desktop browser header"
          className="self-stretch"
        />
        {logoUrl ? (
          <img
            src={logoUrl}
            alt="Logo"
            className="aspect-square h-14 object-contain"
          />
        ) : null}
        <SurveyRatings
          survey={SAMPLE_SURVEY}
          onSelect={(v) => setSelectedRating(v)}
          selectedValue={selectedRating}
          accentColor={accentColor}
          className="mx-6"
        />
        <SurveyChat
          accentColor={accentColor}
          avatarUrl={avatarUrl}
          className="mx-6 self-stretch"
          messages={SAMPLE_MESSAGES}
          onSubmit={Promise.resolve}
          scrollToEnd={false}
        />
        {showPoweredBy && <SurveyPoweredBy />}
      </div>
    </div>
  );
}
