import { gql } from '../__generated__/gql';

export const GET_INSIGHT = gql(/* GraphQL */ `
  query GetInsight($id: UUID!) {
    insight(id: $id) {
      id
      messages {
        id
        content
        createdAt
        finished
        sender
      }
      primaryAnswer
    }
  }
`);
