import { useAuth } from '@workos-inc/authkit-react';
import { useLocation } from 'react-router-dom';

import Loading from './auth.loading';
import Redirecting from './auth.redirecting';

function withAuthenticationRequired<P extends object>(
  Component: React.ComponentType<P>
): React.FC<P> {
  return (props: P): JSX.Element => {
    const { isLoading, signIn, user } = useAuth();
    const location = useLocation();

    if (isLoading) {
      return <Loading />;
    }

    if (user) {
      return <Component {...props} />;
    }

    signIn({ state: { returnTo: location.pathname } });
    return <Redirecting />;
  };
}

interface AuthGuardProps {
  component: React.ComponentType;
}

export default function AuthGuard({ component }: AuthGuardProps) {
  const Component = withAuthenticationRequired(component);
  return <Component />;
}
