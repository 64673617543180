import type { GetDashboardQuery } from '@/manager-graphql';

interface Props {
  rating: NonNullable<
    GetDashboardQuery['dashboard']['ratingDistribution']
  >[number];
}

export default function DashboardRatingsItem({ rating }: Props) {
  const value = rating.percentage > 100 ? 100 : rating.percentage;

  return (
    <div>
      <div className="mb-2 flex justify-between">
        <span className="text-sm text-zinc-600">{rating.score}</span>
        <span className="text-lg leading-tight text-zinc-700">
          {rating.percentage}%
        </span>
      </div>

      <div className="h-1.5 w-full rounded-full bg-zinc-100">
        <div
          className="h-1.5 rounded-full bg-red-600 transition-all duration-300 ease-in-out dark:bg-red-500"
          style={{ width: `${value}%` }}
        />
      </div>
    </div>
  );
}
