import { gql } from '../__generated__/gql';

export const GET_SURVEY = gql(/* GraphQL */ `
  query GetSurvey($id: UUID!) {
    survey(id: $id) {
      id
      company {
        name
        website
      }
      isExpired
      primaryQuestion
      ratings {
        label
        value
      }
    }
  }
`);
