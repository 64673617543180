import { gql } from '../__generated__/gql';

export const GET_MESSAGES = gql(/* GraphQL */ `
  query GetMessages($after: Cursor, $filter: MessageListFilterInput!) {
    messages(after: $after, filter: $filter) {
      items {
        createdAt
        id
        sender
        text
      }
      pageInfo {
        endCursor
        hasNextPage
      }
    }
  }
`);
