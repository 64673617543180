import { gql } from '../__generated__/gql';

export const UPDATE_QUESTION = gql(/* GraphQL */ `
  mutation UpdateQuestion($input: QuestionUpdateInput!) {
    updateQuestion(input: $input) {
      id
      primaryQuestion
      ratings {
        id
        label
        value
      }
    }
  }
`);
