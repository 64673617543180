import { useMutation, useQuery } from '@apollo/client';
import { endOfMonth, startOfMonth } from 'date-fns';
import { atom, useRecoilState } from 'recoil';

import {
  BillingPlanCode,
  CREATE_CUSTOMER_PORTAL_SESSION,
  GET_UPGRADE_DATA,
} from '@/manager-graphql';
import { useMeasurement } from '@/measurement';

const currentDate = new Date();
const since = startOfMonth(currentDate).toISOString();
const until = endOfMonth(currentDate).toISOString();

const upgradeState = atom({
  key: 'upgradeState',
  default: {
    showUpgrade: false,
  },
});

const useCreateCustomerPortalSession = () =>
  useMutation(CREATE_CUSTOMER_PORTAL_SESSION);

export const useUpgradeBilling = () => {
  const [createCustomerPortalSession] = useCreateCustomerPortalSession();
  const handleCustomerPortalSession = async () => {
    const response = await createCustomerPortalSession();
    if (response?.data?.createCustomerPortalSession?.url) {
      window.location.replace(response?.data?.createCustomerPortalSession?.url);
    }
  };
  return { handleCustomerPortalSession };
};

export const useUpgrade = () => {
  const measurement = useMeasurement();

  const { data, loading } = useQuery(GET_UPGRADE_DATA, {
    variables: {
      insightsCountInput: {
        since,
        until,
      },
    },
  });
  const [state, setState] = useRecoilState(upgradeState);
  const { handleCustomerPortalSession } = useUpgradeBilling();

  const planDetails = data?.companyInfo?.planDetails;
  const totalAllowedInsights = planDetails?.totalAllowedInsights ?? 0;
  const totalInsights = data?.companyInfo?.insightsCount ?? 0;
  const remainingInsights = Math.max(totalAllowedInsights - totalInsights, 0);
  const hasReachedLimit =
    totalInsights && totalInsights >= totalAllowedInsights;

  const handleToggleUpgrade = () => {
    measurement?.track('Upgrade plan clicked');

    if (planDetails?.planCode !== BillingPlanCode.Free) {
      handleCustomerPortalSession();
      return;
    }

    setState({
      showUpgrade: !state?.showUpgrade,
    });
  };

  return {
    totalInsights,
    planDetails,
    stripeCustomerId: data?.companyInfo?.stripeCustomerId,
    remainingInsights,
    hasReachedLimit,
    showUpgrade: state?.showUpgrade,
    handleToggleUpgrade,
    loading,
  };
};
