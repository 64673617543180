import { gql } from '../__generated__/gql';

export const ADD_COMPANY = gql(/* GraphQL */ `
  mutation AddCompany($input: OrganizationCreateInput!) {
    addCompany(input: $input) {
      id
      name
      category
      description
      website
      productServices {
        id
        name
        benefits
        features
        uniqueSellingPoints
      }
    }
  }
`);
