import { gql } from '../__generated__/gql';

export const GET_INSIGHTS = gql(/* GraphQL */ `
  query GetInsights($after: Cursor, $filter: InsightListFilterInput!) {
    insights(after: $after, filter: $filter) {
      items {
        id
        contact {
          id
          firstName
          lastName
          email
          phone
        }
        createdAt
        followup
        msgRead
        primaryAnswer
        signal
        summary
        hasMessages
      }
      pageInfo {
        hasNextPage
        endCursor
        total
      }
    }
  }
`);

export const GET_INSIGHTS_LIGHT_WEIGHT = gql(/* GraphQL */ `
  query GetInsightsLightWeight(
    $after: Cursor
    $filter: InsightListFilterInput!
  ) {
    insights(after: $after, filter: $filter) {
      items {
        id
        createdAt
      }
    }
  }
`);
