import { faSpinner } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Fragment } from 'react';
import { InView } from 'react-intersection-observer';
import Markdown from 'react-markdown';
import rehypeExtLinks from 'rehype-external-links';
import z from 'zod';

import { ensureTripleBackticks } from './ui.chat.helpers';
import { useScrollIntoView } from './ui.chat.hooks';

const messageSchema = z.object({
  createdAt: z.union([
    z.date(),
    z
      .string()
      .datetime()
      .transform((d) => new Date(d)),
  ]),
  id: z.string(),
  sender: z.enum(['assistant', 'system', 'user']),
  text: z.string(),
});
const messagesSchema = z.array(messageSchema);

type Messages = z.infer<typeof messagesSchema>;

interface ChatProps {
  loading?: boolean;
  messages: Messages;
  showDateTime?: boolean;
  onLoadMore?: () => void;
  optimisticReplyMessageId?: string;
  optimisticSentMessageId?: string;
  avatarUrl?: string | null;
  scrollToEnd?: boolean;
}

export default function Chat({
  messages,
  showDateTime = false,
  onLoadMore,
  optimisticReplyMessageId,
  optimisticSentMessageId,
  avatarUrl,
  scrollToEnd = true,
}: ChatProps) {
  const parsedMessages = messagesSchema.parse(messages);
  const messageEndRef = useScrollIntoView(parsedMessages);

  return (
    <div className="flex flex-col gap-4 p-4">
      {parsedMessages.map((message, index) => {
        const isDifferentDateFromPrevious =
          index === 0 ||
          parsedMessages[index - 1].createdAt.toDateString() !==
            message.createdAt.toDateString();
        let date = '';
        if (isDifferentDateFromPrevious) {
          const today = new Date();
          const isToday =
            message.createdAt.toDateString() === today.toDateString();
          const isYesterday =
            message.createdAt.toDateString() ===
            new Date(today.setDate(today.getDate() - 1)).toDateString();
          date = isToday
            ? 'Today'
            : isYesterday
            ? 'Yesterday'
            : message.createdAt.toLocaleDateString('en-US', {
                month: 'short',
                day: 'numeric',
                year:
                  message.createdAt.getFullYear() === today.getFullYear()
                    ? undefined
                    : 'numeric',
              });
        }
        const isOptimisticReplyMessage =
          optimisticReplyMessageId && message.id === optimisticReplyMessageId;
        const isOptimisticSentMessage =
          optimisticSentMessageId && message.id === optimisticSentMessageId;

        return (
          <Fragment key={message.id}>
            {showDateTime && isDifferentDateFromPrevious ? (
              <div className="px-3 pb-3 text-center text-xs font-medium leading-normal text-zinc-500">
                {date}
              </div>
            ) : null}

            <div
              data-sender={message.sender}
              className={`group ${message.sender} data-[sender=assistant]:mr-auto data-[sender=user]:ml-auto`}
            >
              <div className="group-[.user]:text-right">
                <div className="relative">
                  {message.sender === 'assistant' ? (
                    <div className="absolute -top-5 flex flex-col items-center gap-1">
                      <img
                        alt="Illustration of a stylized owl with large yellow eyes and gray feathers, perched on a red branch with a serious expression"
                        className="h-9 w-9 rounded-full border border-zinc-200 bg-white"
                        src={
                          avatarUrl ??
                          'https://static.theysaid.io/images/evo.png'
                        }
                      />
                      {isOptimisticReplyMessage ? (
                        <div className="w-12 scale-50 ps-6 opacity-85 grayscale">
                          <div className="loader before:bg-zinc-800 after:bg-zinc-800"></div>
                        </div>
                      ) : null}
                    </div>
                  ) : null}
                  {!isOptimisticReplyMessage ? (
                    <div
                      data-sender={message.sender}
                      data-optimistic={isOptimisticSentMessage}
                      className="prose animate-slide-in-from-bottom float-right w-fit break-words rounded-xl border border-zinc-200 bg-white p-3 text-left text-sm leading-tight text-gray-600 transition-opacity group-[.user]:bg-gray-200 data-[sender=assistant]:pt-5 data-[optimistic=true]:opacity-90"
                    >
                      <Markdown
                        rehypePlugins={[[rehypeExtLinks, { target: '_blank' }]]}
                      >
                        {ensureTripleBackticks(message.text)}
                      </Markdown>
                    </div>
                  ) : null}

                  {showDateTime ? (
                    <span className="mx-3 text-center text-xs font-medium lowercase leading-tight text-neutral-600">
                      {message.createdAt.toLocaleTimeString('en-US', {
                        hour: 'numeric',
                        minute: 'numeric',
                      })}
                    </span>
                  ) : null}
                </div>
              </div>
            </div>
          </Fragment>
        );
      })}

      {onLoadMore ? (
        <InView as="div" onChange={onLoadMore} className="mx-auto mt-4">
          <FontAwesomeIcon icon={faSpinner} className="animate-spin" />
        </InView>
      ) : null}

      <div ref={scrollToEnd ? messageEndRef : undefined} />
    </div>
  );
}
