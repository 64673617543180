import { useMutation, useQuery } from '@apollo/client';
import {
  startOfDay,
  endOfDay,
  parseISO,
  format,
  isValid,
  subDays,
} from 'date-fns';
import { useCallback, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { z } from 'zod';

import {
  GENERATE_DASHBOARD_SUMMARY,
  GET_DASHBOARD,
  GetDashboardDocument,
} from '@/manager-graphql';
import { useToast } from '@/shared-toast';
import { useMeasurement } from '@/measurement';

export const useGenerateDashboardSummary = () =>
  useMutation(GENERATE_DASHBOARD_SUMMARY);

export const DEFAULT_RANGE_FILTER_SESSION_STORAGE_KEY =
  'defaultRangeFilter' as const;

const filterRangeFromSessionStorageSchema = z.object({
  since: z.string(),
  until: z.string(),
});

function getFilterFromQueryParams(searchParams: URLSearchParams) {
  const defaultRange = getDefaultRangeFilterFromSessionStorage();
  const since = searchParams.get('since');
  const until = searchParams.get('until');
  if (!since || !until) {
    return {
      filter: defaultRange,
      isDefault: true,
    };
  }
  const sinceDate = parseISO(since);
  const untilDate = parseISO(until);

  if (!isValid(sinceDate) || !isValid(untilDate)) {
    return {
      filter: defaultRange,
      isDefault: true,
    };
  }

  return {
    filter: {
      since: startOfDay(sinceDate),
      until: endOfDay(untilDate),
    },
    isDefault: false,
  };
}

function getDefaultRangeFilterFromSessionStorage(): {
  since: Date;
  until: Date;
} {
  const defaultRange = {
    since: startOfDay(subDays(new Date(), 29)),
    until: startOfDay(new Date()),
  };
  const item = sessionStorage.getItem(DEFAULT_RANGE_FILTER_SESSION_STORAGE_KEY);
  if (!item) {
    return defaultRange;
  }
  try {
    const range = JSON.parse(item);
    const validatedRange = filterRangeFromSessionStorageSchema.safeParse(range);
    if (validatedRange.success) {
      const itemWithDates = {
        since: parseISO(validatedRange.data.since),
        until: parseISO(validatedRange.data.until),
      };
      if (!isValid(itemWithDates.since) || !isValid(itemWithDates.until)) {
        return defaultRange;
      }
      return itemWithDates;
    }
    return defaultRange;
  } catch {
    return defaultRange;
  }
}

export function useDashboardFilter() {
  const [searchParams, setUrlSearchParams] = useSearchParams();
  const { filter, isDefault } = getFilterFromQueryParams(searchParams);
  
  useEffect(
    () => {
      if (isDefault) {
        setUrlSearchParams((prev) => {
          const newParams = new URLSearchParams(prev);
          newParams.set('since', format(filter.since, 'yyyy-MM-dd'));
          newParams.set('until', format(filter.until, 'yyyy-MM-dd'));
          return newParams;
        });
      }
    }, [isDefault, filter.since, filter.until, setUrlSearchParams]
  )

  const setFilter = useCallback(
    (newFilter: { since: Date; until: Date }) => {
      setUrlSearchParams((prev) => {
        const newParams = new URLSearchParams(prev);
        newParams.set('since', format(newFilter.since, 'yyyy-MM-dd'));
        newParams.set('until', format(newFilter.until, 'yyyy-MM-dd'));
        return newParams;
      });
      sessionStorage.setItem(
        DEFAULT_RANGE_FILTER_SESSION_STORAGE_KEY,
        JSON.stringify(newFilter)
      );
    },
    [setUrlSearchParams]
  );
  return [filter, setFilter] as const;
}

export function useDashboard() {
  const [filter] = useDashboardFilter();
  const measurement = useMeasurement();
  const { addToast } = useToast();
  const {
    data: dashboardData,
    error: dashboardError,
    loading: loadingDashboard,
  } = useQuery(GET_DASHBOARD, {
    fetchPolicy: 'cache-and-network',
    variables: { filter },
  });
  const [generateSummary, { error: summaryError, loading: loadingSummary }] =
    useGenerateDashboardSummary();
  const handleGenerateSummary = useCallback(() => {
    generateSummary({
      variables: { input: filter },
      update: (cache, { data }) => {
        if (dashboardData && data?.generateDashboardSummary) {
          cache.writeQuery({
            query: GetDashboardDocument,
            variables: { filter },
            data: {
              dashboard: {
                ...dashboardData.dashboard,
                summary: data.generateDashboardSummary,
              },
            },
          });
        } else if (dashboardData && data?.generateDashboardSummary === null) {
          addToast({
            title: 'No summary yet',
            type: 'info',
          });
        }
        measurement?.track('Generate Summary clicked');
      },
    });
  }, [generateSummary, filter, dashboardData, addToast, measurement]);

  return {
    dashboard: dashboardData?.dashboard,
    error: dashboardError?.message || summaryError?.message,
    loadingDashboard,
    loadingSummary,
    onGenerateSummary: handleGenerateSummary,
  };
}
