import { faPaperPlaneAlt, faSpinner } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { zodResolver } from '@hookform/resolvers/zod';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';

import {
  messageFormSchema,
  type MessageFormValues,
} from './survey-response-form.helpers';

interface Props {
  disabled?: boolean;
  onSubmit: (values: MessageFormValues) => Promise<void>;
  accentColor?: string;
}

export default function SurveyMessageForm({
  disabled,
  onSubmit,
  accentColor,
}: Props) {
  const {
    formState: { errors, isSubmitting },
    handleSubmit,
    register,
    reset,
    setFocus,
  } = useForm<MessageFormValues>({
    resolver: zodResolver(messageFormSchema),
    defaultValues: { content: '' },
  });

  useEffect(() => {
    !disabled && setFocus('content');
  }, [disabled, setFocus]);

  const resetAndSubmit = async (data: MessageFormValues) => {
    reset();
    await onSubmit(data);
  }

  const submitOnEnter = (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (event.key === 'Enter' && event.shiftKey === false) {
      event.preventDefault();
      handleSubmit(resetAndSubmit)();
    }
  };

  return (
    <form
      onSubmit={handleSubmit(resetAndSubmit)}
      className="flex items-center justify-stretch gap-2 rounded-md border bg-white p-1 pl-3 data-[submitting=true]:bg-gray-100"
      data-submitting={isSubmitting}
    >
      <textarea
        {...register('content')}
        data-readonly={isSubmitting}
        className="field-sizing-content w-full resize-none text-sm leading-normal text-gray-600 placeholder-neutral-400 focus:outline-none disabled:bg-white data-[readonly=true]:bg-gray-100"
        placeholder="Ask me something..."
        disabled={disabled}
        readOnly={isSubmitting}
        onKeyDown={submitOnEnter}
      />

      <button
        disabled={disabled || Object.keys(errors).length > 0 || isSubmitting}
        className="inline-flex h-8 w-8 items-center justify-center self-end rounded-md bg-black p-2 text-white transition-colors focus:outline-none enabled:hover:bg-neutral-700 disabled:cursor-not-allowed disabled:bg-neutral-400"
        type="submit"
        style={{
          backgroundColor: accentColor,
        }}
      >
        {isSubmitting ? (
          <FontAwesomeIcon icon={faSpinner} spin />
        ) : (
          <FontAwesomeIcon icon={faPaperPlaneAlt} className="-rotate-45" />
        )}
      </button>
    </form>
  );
}
