import {
  type AddMessageMutation,
  type GetInsightQuery,
} from '@/survey-graphql';

const STORAGE_KEYS = {
  insightId: '@TheySaid:insightId',
} as const;

export const getInsightIdFromStorage = (surveyId: string) => {
  return typeof window !== 'undefined'
    ? localStorage.getItem(`${STORAGE_KEYS.insightId}:${surveyId}`)
    : null;
};

export const setInsightIdFromStorage = (surveyId: string, value: string) =>
  localStorage.setItem(`${STORAGE_KEYS.insightId}:${surveyId}`, value);

export function parseRating(rating?: string | string[] | null): number | null {
  if (!rating) {
    return null;
  }
  const value = Array.isArray(rating) ? rating[0] : rating;
  const parsedValue = typeof value === 'string' ? parseInt(value, 10) : null;
  if (parsedValue === null) {
    return null;
  }
  return Number.isNaN(parsedValue) ? null : parsedValue;
}

export const SURVEY_RATING_COMPONENT_ID = 'survey-rating';

export function upsertMessage(
  messages: NonNullable<GetInsightQuery['insight']['messages']>,
  newMessage: AddMessageMutation['addNewMessage']['sent']
): NonNullable<GetInsightQuery['insight']['messages']> {
  const existingMessageIndex = messages.findIndex(
    (message) => message.id === newMessage.id
  );
  if (existingMessageIndex === -1) {
    return [...messages, newMessage];
  }
  return messages.map((m) => (m.id === newMessage.id ? newMessage : m));
}
