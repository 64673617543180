import { Chat, cn } from '@/shared-ui';
import { faXmark } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useReducer } from 'react';
import SurveyMessageForm from './survey-response-form';

interface Props
  extends React.ComponentProps<typeof Chat>,
    React.ComponentProps<typeof SurveyMessageForm> {
  showWarning?: boolean;
  className?: string;
  accentColor?: string;
  avatarUrl?: string | null;
  scrollToEnd?: boolean;
}

export default function SurveyChat({
  disabled,
  loading,
  messages,
  showWarning = false,
  onSubmit,
  optimisticReplyMessageId,
  optimisticSentMessageId,
  className,
  accentColor,
  avatarUrl,
  scrollToEnd = true,
}: Props) {
  const [showWarn, dismissWarn] = useReducer((prev) => !prev, showWarning);

  return (
    <div
      className={cn(
        'flex flex-grow flex-col items-center rounded-2xl border border-gray-100 bg-white shadow-sm',
        className
      )}
    >
      <div className="w-full p-4">
        <h3 className="font-display text-center text-sm font-semibold leading-none text-zinc-800">
          Chat with the world’s first AI survey{' '}
          <span role="img" aria-label="Sparkles">
            ✨
          </span>
        </h3>
      </div>

      <div className="relative min-h-60 w-full flex-grow bg-gradient-to-b from-[rgba(243,_244,_246,_0.24)] to-[rgba(252,_231,_243,_0.24)]">
        <div
          data-loading={loading}
          className="absolute inset-0 overflow-y-auto pt-2 data-[loading=true]:pb-4"
        >
          <Chat
            messages={messages}
            loading={loading}
            optimisticReplyMessageId={optimisticReplyMessageId}
            optimisticSentMessageId={optimisticSentMessageId}
            avatarUrl={avatarUrl}
            scrollToEnd={scrollToEnd}
          />
        </div>
      </div>

      {showWarn ? (
        <div className="relative h-6 w-full bg-gray-200 text-center">
          <span className="text-xs leading-none text-gray-600">
            AI can make mistakes. Check important info.
          </span>
          <button
            className="absolute right-1.5 top-0.5 h-4 w-4 p-0.5 text-gray-600"
            onClick={dismissWarn}
          >
            <FontAwesomeIcon icon={faXmark} />
          </button>
        </div>
      ) : null}

      <div className="w-full p-4">
        <SurveyMessageForm
          disabled={disabled}
          onSubmit={onSubmit}
          accentColor={accentColor}
        />
      </div>
    </div>
  );
}
