import { gql } from '../__generated__/gql';

export const TOGGLE_SURVEYS = gql(/* GraphQL */ `
  mutation ToggleSurveys($input: ToggleSurveysInput!) {
    toggleSurveys(input: $input) {
      id
      isActive
    }
  }
`);
