import { gql } from '../__generated__/gql';

export const ADD_SURVEY = gql(/* GraphQL */ `
  mutation AddSurvey($input: SurveyCreateInput!) {
    addSurvey(input: $input) {
      expiresAt
      id
    }
  }
`);
