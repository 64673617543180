import { SurveyChat, SurveyPoweredBy, SurveyRatings } from '@/survey-response';
import { ComponentProps, useState } from 'react';
import ManagerBrandingPreviewSection from './manager-branding-preview-section';
import { SAMPLE_MESSAGES, SAMPLE_SURVEY } from './manager-branding.helpers';

export default function ManagerBrandingWidgetPreview({
  accentColor,
  logoUrl,
  avatarUrl,
  showPoweredBy,
}: ComponentProps<typeof ManagerBrandingPreviewSection>) {
  const [selectedRating, setSelectedRating] = useState<number | null>(2);

  return (
    <div className="animate-fade-in flex flex-col gap-0 rounded-xl bg-gray-50 p-6">
      <img
        src="/img/desktop-browser-header.svg"
        alt="Desktop browser header"
        className="h-9 w-full object-cover"
      />
      <div className="relative -mt-0.5 flex min-h-[36rem] w-full flex-col items-center self-stretch rounded-lg bg-white py-6">
        <div className="absolute bottom-6 right-8 flex min-h-[54rem] origin-bottom-right scale-[0.6] flex-col gap-4 overflow-y-auto rounded-2xl bg-gray-100 px-4 py-6">
          {logoUrl ? (
            <img
              src={logoUrl}
              alt="Logo"
              className="aspect-square h-14 object-contain"
            />
          ) : null}
          <SurveyRatings
            survey={SAMPLE_SURVEY}
            onSelect={(v) => setSelectedRating(v)}
            selectedValue={selectedRating}
            accentColor={accentColor}
          />
          <SurveyChat
            accentColor={accentColor}
            avatarUrl={avatarUrl}
            className=""
            messages={SAMPLE_MESSAGES}
            onSubmit={Promise.resolve}
            scrollToEnd={false}
          />
          {showPoweredBy && (
            <div>
              <SurveyPoweredBy />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
