import { gql } from '../__generated__/gql';

export const ADD_PROJECT = gql(/* GraphQL */ `
  mutation AddProject($input: CreateProjectInput!) {
    createProject(input: $input) {
      id
      title
      published
      redirectUrl
      questions {
        id
        type
        text
        required
        aiInsights
        aiConvoDepth
        multipleSelect
        randomize
        options {
          id
          label
          value
        }
      }
      tags {
        id
        name
        color
      }
    }
  }
`);
