import { useMutation, useQuery } from '@apollo/client';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import {
  ADD_SURVEY,
  AddSurveyMutationVariables,
  GET_QUESTIONS,
  OrderDirection,
  QuestionOrderField,
} from '@/manager-graphql';
import { useToast } from '@/shared-toast';
import { SYSTEM_TAG } from './manger-onboarding.helpers';

// New hook for managing home state
export const useOnboardingQuestions = () => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const navigate = useNavigate();
  const { addToast } = useToast();

  const { data, error, loading } = useQuery(GET_QUESTIONS, {
    variables: {
      filter: { tags: [SYSTEM_TAG] },
      orderBy: {
        direction: OrderDirection.Desc,
        field: QuestionOrderField.CreatedAt,
      },
    },
  });

  const [addSurvey] = useMutation(ADD_SURVEY);

  const handleAddSurvey = async (
    input: AddSurveyMutationVariables['input']
  ) => {
    setIsSubmitting(true);
    try {
      await addSurvey({
        variables: { input },
        onCompleted: (data) => {
          setIsSubmitting(false);
          const surveyId = data.addSurvey?.id;
          if (surveyId) {
            navigate(`/home/onboarding/survey/${surveyId}`, {
              state: {
                questionId: input?.questionId,
              },
            });
          } else {
            throw new Error('Survey ID not returned');
          }
        },
        onError: (error) => {
          setIsSubmitting(false);
          throw error;
        },
      });
    } catch (error) {
      setIsSubmitting(false);
      addToast({
        type: 'error',
        title: 'Failed to add survey. Please try again.',
      });
      console.error('Error adding survey:', error);
    }
  };

  return {
    questions: data?.questions?.items || [],
    isSubmitting,
    error,
    isLoading: loading,
    handleAddSurvey,
  };
};
