import { gql } from '../__generated__/gql';

export const GET_DASHBOARD = gql(/* GraphQL */ `
  query GetDashboard($filter: DashboardFilterInput!) {
    dashboard(filter: $filter) {
      totalGrowthSignals
      totalInsights
      totalRiskSignals
      avgMessageDepth
      avgScore
      npsScore
      ratingDistribution {
        percentage
        score
      }
      summary
    }
  }
`);
