import { gql } from '../__generated__/gql';

export const ADD_QUESTION = gql(/* GraphQL */ `
  mutation AddQuestion($input: CreateQuestionInput!) {
    createCustomQuestion(input: $input) {
      id
      primaryQuestion
      ratings {
        id
        label
        value
      }
      tags {
        id
        name
      }
    }
  }
`);
