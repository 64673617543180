/* eslint-disable */
import * as types from './graphql';
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';

/**
 * Map of all GraphQL operations in the project.
 *
 * This map has several performance disadvantages:
 * 1. It is not tree-shakeable, so it will include all operations in the project.
 * 2. It is not minifiable, so the string of a GraphQL query will be multiple times inside the bundle.
 * 3. It does not support dead code elimination, so it will add unused operations.
 *
 * Therefore it is highly recommended to use the babel or swc plugin for production.
 */
const documents = {
    "\n  mutation AddInsight($input: AddInsightInput!) {\n    addInsight(input: $input) {\n      id\n      messages {\n        id\n        content\n        createdAt\n        finished\n        sender\n      }\n      primaryAnswer\n    }\n  }\n": types.AddInsightDocument,
    "\n  mutation AddMessage($input: AddMessageInput!) {\n    addNewMessage(input: $input) {\n      sent {\n        id\n        content\n        createdAt\n        finished\n        sender\n      }\n      reply {\n        id\n        content\n        createdAt\n        finished\n        sender\n      }\n    }\n  }\n": types.AddMessageDocument,
    "\n  query GetInsight($id: UUID!) {\n    insight(id: $id) {\n      id\n      messages {\n        id\n        content\n        createdAt\n        finished\n        sender\n      }\n      primaryAnswer\n    }\n  }\n": types.GetInsightDocument,
    "\n  query GetSurveyOrganizationSettings($surveyId: UUID!) {\n    surveyOrgSettings(surveyId: $surveyId) {\n      avatarUrl\n      logoUrl\n      brandColor\n      hideTheysaidLogo\n    }\n  }\n": types.GetSurveyOrganizationSettingsDocument,
    "\n  query GetSurvey($id: UUID!) {\n    survey(id: $id) {\n      id\n      company {\n        name\n        website\n      }\n      isExpired\n      primaryQuestion\n      ratings {\n        label\n        value\n      }\n    }\n  }\n": types.GetSurveyDocument,
    "\n  subscription MessageAdded($insightId: UUID!) {\n    messageAdded(insightId: $insightId) {\n      id\n      content\n      createdAt\n      finished\n      sender\n    }\n  }\n": types.MessageAddedDocument,
    "\n  mutation UpdateInsight($input: UpdateInsightInput!) {\n    updateInsight(input: $input) {\n      id\n    }\n  }\n": types.UpdateInsightDocument,
};

/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 *
 *
 * @example
 * ```ts
 * const query = gql(`query GetUser($id: ID!) { user(id: $id) { name } }`);
 * ```
 *
 * The query argument is unknown!
 * Please regenerate the types.
 */
export function gql(source: string): unknown;

/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation AddInsight($input: AddInsightInput!) {\n    addInsight(input: $input) {\n      id\n      messages {\n        id\n        content\n        createdAt\n        finished\n        sender\n      }\n      primaryAnswer\n    }\n  }\n"): (typeof documents)["\n  mutation AddInsight($input: AddInsightInput!) {\n    addInsight(input: $input) {\n      id\n      messages {\n        id\n        content\n        createdAt\n        finished\n        sender\n      }\n      primaryAnswer\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation AddMessage($input: AddMessageInput!) {\n    addNewMessage(input: $input) {\n      sent {\n        id\n        content\n        createdAt\n        finished\n        sender\n      }\n      reply {\n        id\n        content\n        createdAt\n        finished\n        sender\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation AddMessage($input: AddMessageInput!) {\n    addNewMessage(input: $input) {\n      sent {\n        id\n        content\n        createdAt\n        finished\n        sender\n      }\n      reply {\n        id\n        content\n        createdAt\n        finished\n        sender\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query GetInsight($id: UUID!) {\n    insight(id: $id) {\n      id\n      messages {\n        id\n        content\n        createdAt\n        finished\n        sender\n      }\n      primaryAnswer\n    }\n  }\n"): (typeof documents)["\n  query GetInsight($id: UUID!) {\n    insight(id: $id) {\n      id\n      messages {\n        id\n        content\n        createdAt\n        finished\n        sender\n      }\n      primaryAnswer\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query GetSurveyOrganizationSettings($surveyId: UUID!) {\n    surveyOrgSettings(surveyId: $surveyId) {\n      avatarUrl\n      logoUrl\n      brandColor\n      hideTheysaidLogo\n    }\n  }\n"): (typeof documents)["\n  query GetSurveyOrganizationSettings($surveyId: UUID!) {\n    surveyOrgSettings(surveyId: $surveyId) {\n      avatarUrl\n      logoUrl\n      brandColor\n      hideTheysaidLogo\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query GetSurvey($id: UUID!) {\n    survey(id: $id) {\n      id\n      company {\n        name\n        website\n      }\n      isExpired\n      primaryQuestion\n      ratings {\n        label\n        value\n      }\n    }\n  }\n"): (typeof documents)["\n  query GetSurvey($id: UUID!) {\n    survey(id: $id) {\n      id\n      company {\n        name\n        website\n      }\n      isExpired\n      primaryQuestion\n      ratings {\n        label\n        value\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  subscription MessageAdded($insightId: UUID!) {\n    messageAdded(insightId: $insightId) {\n      id\n      content\n      createdAt\n      finished\n      sender\n    }\n  }\n"): (typeof documents)["\n  subscription MessageAdded($insightId: UUID!) {\n    messageAdded(insightId: $insightId) {\n      id\n      content\n      createdAt\n      finished\n      sender\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation UpdateInsight($input: UpdateInsightInput!) {\n    updateInsight(input: $input) {\n      id\n    }\n  }\n"): (typeof documents)["\n  mutation UpdateInsight($input: UpdateInsightInput!) {\n    updateInsight(input: $input) {\n      id\n    }\n  }\n"];

export function gql(source: string) {
  return (documents as any)[source] ?? {};
}

export type DocumentType<TDocumentNode extends DocumentNode<any, any>> = TDocumentNode extends DocumentNode<  infer TType,  any>  ? TType  : never;