/* eslint-disable */
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  /** A Cursor is an opaque string that indicates a specific point in the list. */
  Cursor: { input: any; output: any; }
  /** A date-time string at UTC, such as 2007-12-03T10:15:30Z, compliant with the `date-time` format outlined in section 5.6 of the RFC 3339 profile of the ISO 8601 standard for representation of dates and times using the Gregorian calendar. */
  DateTime: { input: any; output: any; }
  /** A field whose value conforms to the standard internet email address format as specified in HTML Spec: https://html.spec.whatwg.org/multipage/input.html#valid-e-mail-address. */
  EmailAddress: { input: any; output: any; }
  /** A field whose value is a hex color code: https://en.wikipedia.org/wiki/Web_colors. */
  HexColorCode: { input: any; output: any; }
  /** A field whose value conforms to the standard URL format as specified in RFC3986: https://www.ietf.org/rfc/rfc3986.txt. */
  URL: { input: any; output: any; }
  /** A field whose value is a generic Universally Unique Identifier: https://en.wikipedia.org/wiki/Universally_unique_identifier. */
  UUID: { input: any; output: any; }
};

export type AddInsightInput = {
  /** The contact ID of the insight, if available */
  contactId?: InputMaybe<Scalars['UUID']['input']>;
  /** The email of the contact, if available */
  email?: InputMaybe<Scalars['String']['input']>;
  primaryAnswer: Scalars['Int']['input'];
  surveyId: Scalars['UUID']['input'];
};

export type AddMessageInput = {
  /** The message content */
  content?: InputMaybe<Scalars['String']['input']>;
  insightId: Scalars['UUID']['input'];
  message?: InputMaybe<Scalars['String']['input']>;
};

export type Company = {
  __typename?: 'Company';
  /** The name of the company. */
  name: Scalars['String']['output'];
  /** The website of the company. */
  website: Scalars['URL']['output'];
};

export type Contact = {
  __typename?: 'Contact';
  email: Scalars['String']['output'];
  firstName: Scalars['String']['output'];
  id: Scalars['UUID']['output'];
  lastName?: Maybe<Scalars['String']['output']>;
  phone?: Maybe<Scalars['String']['output']>;
};

export type Insight = {
  __typename?: 'Insight';
  contact?: Maybe<Contact>;
  id: Scalars['UUID']['output'];
  messages?: Maybe<Array<Message>>;
  primaryAnswer?: Maybe<Scalars['Int']['output']>;
};

export type Invitation = {
  __typename?: 'Invitation';
  /** The email address of the recipient. */
  email: Scalars['EmailAddress']['output'];
  /** The unique ID of the invitation. */
  id: Scalars['ID']['output'];
};

export type Message = {
  __typename?: 'Message';
  /** The message content */
  content: Scalars['String']['output'];
  /** The message creation date */
  createdAt: Scalars['DateTime']['output'];
  /** Whether the message is finished */
  finished: Scalars['Boolean']['output'];
  id: Scalars['UUID']['output'];
  /** The sender of the message */
  sender: MessageSender;
  /** @deprecated Use `content` instead */
  text: Scalars['String']['output'];
};

export type MessagePair = {
  __typename?: 'MessagePair';
  /** The message that was received as a reply */
  reply: Message;
  /** The message that was sent by the user */
  sent: Message;
};

/** The sender of the message */
export enum MessageSender {
  Assistant = 'assistant',
  System = 'system',
  User = 'user'
}

export type Mutation = {
  __typename?: 'Mutation';
  addInsight: Insight;
  addMessage: MessagePair;
  /** @deprecated Use addMessage instead */
  addNewMessage: MessagePair;
  generateSummary: Scalars['String']['output'];
  updateInsight: Insight;
};


export type MutationAddInsightArgs = {
  input: AddInsightInput;
};


export type MutationAddMessageArgs = {
  input: AddMessageInput;
};


export type MutationAddNewMessageArgs = {
  input: AddMessageInput;
};


export type MutationGenerateSummaryArgs = {
  insightId: Scalars['UUID']['input'];
};


export type MutationUpdateInsightArgs = {
  input: UpdateInsightInput;
};

export type PageInfo = {
  __typename?: 'PageInfo';
  /** When paginating forwards, the cursor to continue. */
  endCursor?: Maybe<Scalars['Cursor']['output']>;
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars['Boolean']['output'];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars['Boolean']['output'];
  /** When paginating backwards, the cursor to continue. */
  startCursor?: Maybe<Scalars['Cursor']['output']>;
  /** Identifies the total count of items in the connection. */
  total?: Maybe<Scalars['Int']['output']>;
};

export type Query = {
  __typename?: 'Query';
  insight: Insight;
  /** Get question details for the given question ID */
  question: Question;
  /** Get survey details for the given ID */
  survey: Survey;
  /** Get organization settings for a survey. This will be deprecated in the future. */
  surveyOrgSettings?: Maybe<SurveyOrganizationSettings>;
};


export type QueryInsightArgs = {
  id: Scalars['UUID']['input'];
};


export type QueryQuestionArgs = {
  id: Scalars['UUID']['input'];
};


export type QuerySurveyArgs = {
  id: Scalars['UUID']['input'];
};


export type QuerySurveyOrgSettingsArgs = {
  surveyId: Scalars['UUID']['input'];
};

export type Question = {
  __typename?: 'Question';
  /** The unique ID of the question. */
  id: Scalars['UUID']['output'];
  /** The primary question. */
  primaryQuestion: Scalars['String']['output'];
  /** The ratings of the question. */
  ratings: Array<Rating>;
  /** The tags of the question. */
  tags?: Maybe<Array<Tag>>;
};

export type Rating = {
  __typename?: 'Rating';
  /** The unique ID of the rating. */
  id: Scalars['UUID']['output'];
  /** The label of the rating. */
  label: Scalars['String']['output'];
  /** The value of the rating. */
  value: Scalars['Int']['output'];
};

export type Subscription = {
  __typename?: 'Subscription';
  messageAdded: Message;
};


export type SubscriptionMessageAddedArgs = {
  insightId: Scalars['UUID']['input'];
};

export type Survey = {
  __typename?: 'Survey';
  /** The company the survey is for. */
  company: Company;
  /** The unique ID of the survey. */
  id: Scalars['UUID']['output'];
  /** Whether the time to take the survey has expired. */
  isExpired: Scalars['Boolean']['output'];
  /** The primary question. */
  primaryQuestion: Scalars['String']['output'];
  /** The ratings of the question. */
  ratings: Array<Rating>;
};

export type SurveyOrganizationSettings = {
  __typename?: 'SurveyOrganizationSettings';
  /** URL of the organization avatar */
  avatarUrl?: Maybe<Scalars['String']['output']>;
  /** Background color in hex format (e.g. #FF0000) */
  backgroundColor?: Maybe<Scalars['HexColorCode']['output']>;
  /** Primary brand color in hex format (e.g. #FF0000) */
  brandColor?: Maybe<Scalars['HexColorCode']['output']>;
  /** Whether to hide the TheySaid logo */
  hideTheysaidLogo: Scalars['Boolean']['output'];
  /** URL of the organization logo */
  logoUrl?: Maybe<Scalars['String']['output']>;
};

export type Tag = {
  __typename?: 'Tag';
  color?: Maybe<Scalars['HexColorCode']['output']>;
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export type UpdateInsightInput = {
  id: Scalars['UUID']['input'];
  primaryAnswer: Scalars['Int']['input'];
};

export type User = {
  __typename?: 'User';
  /** The email address of the user. */
  email: Scalars['EmailAddress']['output'];
  /** The first name of the user. */
  firstName?: Maybe<Scalars['String']['output']>;
  /** The unique ID of the user. */
  id: Scalars['ID']['output'];
  /** The last name of the user. */
  lastName?: Maybe<Scalars['String']['output']>;
  /** A URL reference to an image representing the user. */
  profilePictureUrl?: Maybe<Scalars['URL']['output']>;
};

export type AddInsightMutationVariables = Exact<{
  input: AddInsightInput;
}>;


export type AddInsightMutation = { __typename?: 'Mutation', addInsight: { __typename?: 'Insight', id: any, primaryAnswer?: number | null, messages?: Array<{ __typename?: 'Message', id: any, content: string, createdAt: any, finished: boolean, sender: MessageSender }> | null } };

export type AddMessageMutationVariables = Exact<{
  input: AddMessageInput;
}>;


export type AddMessageMutation = { __typename?: 'Mutation', addNewMessage: { __typename?: 'MessagePair', sent: { __typename?: 'Message', id: any, content: string, createdAt: any, finished: boolean, sender: MessageSender }, reply: { __typename?: 'Message', id: any, content: string, createdAt: any, finished: boolean, sender: MessageSender } } };

export type GetInsightQueryVariables = Exact<{
  id: Scalars['UUID']['input'];
}>;


export type GetInsightQuery = { __typename?: 'Query', insight: { __typename?: 'Insight', id: any, primaryAnswer?: number | null, messages?: Array<{ __typename?: 'Message', id: any, content: string, createdAt: any, finished: boolean, sender: MessageSender }> | null } };

export type GetSurveyOrganizationSettingsQueryVariables = Exact<{
  surveyId: Scalars['UUID']['input'];
}>;


export type GetSurveyOrganizationSettingsQuery = { __typename?: 'Query', surveyOrgSettings?: { __typename?: 'SurveyOrganizationSettings', avatarUrl?: string | null, logoUrl?: string | null, brandColor?: any | null, hideTheysaidLogo: boolean } | null };

export type GetSurveyQueryVariables = Exact<{
  id: Scalars['UUID']['input'];
}>;


export type GetSurveyQuery = { __typename?: 'Query', survey: { __typename?: 'Survey', id: any, isExpired: boolean, primaryQuestion: string, company: { __typename?: 'Company', name: string, website: any }, ratings: Array<{ __typename?: 'Rating', label: string, value: number }> } };

export type MessageAddedSubscriptionVariables = Exact<{
  insightId: Scalars['UUID']['input'];
}>;


export type MessageAddedSubscription = { __typename?: 'Subscription', messageAdded: { __typename?: 'Message', id: any, content: string, createdAt: any, finished: boolean, sender: MessageSender } };

export type UpdateInsightMutationVariables = Exact<{
  input: UpdateInsightInput;
}>;


export type UpdateInsightMutation = { __typename?: 'Mutation', updateInsight: { __typename?: 'Insight', id: any } };


export const AddInsightDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"AddInsight"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"AddInsightInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"addInsight"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"messages"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"content"}},{"kind":"Field","name":{"kind":"Name","value":"createdAt"}},{"kind":"Field","name":{"kind":"Name","value":"finished"}},{"kind":"Field","name":{"kind":"Name","value":"sender"}}]}},{"kind":"Field","name":{"kind":"Name","value":"primaryAnswer"}}]}}]}}]} as unknown as DocumentNode<AddInsightMutation, AddInsightMutationVariables>;
export const AddMessageDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"AddMessage"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"AddMessageInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"addNewMessage"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"sent"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"content"}},{"kind":"Field","name":{"kind":"Name","value":"createdAt"}},{"kind":"Field","name":{"kind":"Name","value":"finished"}},{"kind":"Field","name":{"kind":"Name","value":"sender"}}]}},{"kind":"Field","name":{"kind":"Name","value":"reply"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"content"}},{"kind":"Field","name":{"kind":"Name","value":"createdAt"}},{"kind":"Field","name":{"kind":"Name","value":"finished"}},{"kind":"Field","name":{"kind":"Name","value":"sender"}}]}}]}}]}}]} as unknown as DocumentNode<AddMessageMutation, AddMessageMutationVariables>;
export const GetInsightDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"GetInsight"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"id"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"UUID"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"insight"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"id"},"value":{"kind":"Variable","name":{"kind":"Name","value":"id"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"messages"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"content"}},{"kind":"Field","name":{"kind":"Name","value":"createdAt"}},{"kind":"Field","name":{"kind":"Name","value":"finished"}},{"kind":"Field","name":{"kind":"Name","value":"sender"}}]}},{"kind":"Field","name":{"kind":"Name","value":"primaryAnswer"}}]}}]}}]} as unknown as DocumentNode<GetInsightQuery, GetInsightQueryVariables>;
export const GetSurveyOrganizationSettingsDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"GetSurveyOrganizationSettings"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"surveyId"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"UUID"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"surveyOrgSettings"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"surveyId"},"value":{"kind":"Variable","name":{"kind":"Name","value":"surveyId"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"avatarUrl"}},{"kind":"Field","name":{"kind":"Name","value":"logoUrl"}},{"kind":"Field","name":{"kind":"Name","value":"brandColor"}},{"kind":"Field","name":{"kind":"Name","value":"hideTheysaidLogo"}}]}}]}}]} as unknown as DocumentNode<GetSurveyOrganizationSettingsQuery, GetSurveyOrganizationSettingsQueryVariables>;
export const GetSurveyDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"GetSurvey"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"id"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"UUID"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"survey"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"id"},"value":{"kind":"Variable","name":{"kind":"Name","value":"id"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"company"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"name"}},{"kind":"Field","name":{"kind":"Name","value":"website"}}]}},{"kind":"Field","name":{"kind":"Name","value":"isExpired"}},{"kind":"Field","name":{"kind":"Name","value":"primaryQuestion"}},{"kind":"Field","name":{"kind":"Name","value":"ratings"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"label"}},{"kind":"Field","name":{"kind":"Name","value":"value"}}]}}]}}]}}]} as unknown as DocumentNode<GetSurveyQuery, GetSurveyQueryVariables>;
export const MessageAddedDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"subscription","name":{"kind":"Name","value":"MessageAdded"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"insightId"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"UUID"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"messageAdded"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"insightId"},"value":{"kind":"Variable","name":{"kind":"Name","value":"insightId"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"content"}},{"kind":"Field","name":{"kind":"Name","value":"createdAt"}},{"kind":"Field","name":{"kind":"Name","value":"finished"}},{"kind":"Field","name":{"kind":"Name","value":"sender"}}]}}]}}]} as unknown as DocumentNode<MessageAddedSubscription, MessageAddedSubscriptionVariables>;
export const UpdateInsightDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"UpdateInsight"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"UpdateInsightInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"updateInsight"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}}]}}]}}]} as unknown as DocumentNode<UpdateInsightMutation, UpdateInsightMutationVariables>;