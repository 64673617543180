import { faCircleInfo } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import type { GetDashboardQuery } from '@/manager-graphql';
import { Gauge, Tooltip } from '@/shared-ui';

type Props = Pick<
  GetDashboardQuery['dashboard'],
  | 'avgMessageDepth'
  | 'avgScore'
  | 'npsScore'
  | 'totalGrowthSignals'
  | 'totalInsights'
  | 'totalRiskSignals'
>;

export default function DashboardStatistics({
  avgMessageDepth,
  avgScore,
  npsScore,
  totalGrowthSignals,
  totalInsights,
  totalRiskSignals,
}: Props) {
  const parsedStats = [
    {
      name: 'Insights',
      value: totalInsights,
      tooltip: 'Total number of survey responses',
    },
    {
      name: 'Avg Score',
      value: avgScore?.toFixed(1),
      tooltip: 'Average numeric score provided by survey responders',
    },
    {
      name: 'NPS Score',
      value: npsScore?.toFixed(0),
      tooltip: 'NPS score provided by survey responders',
      showGauge: true,
    },
    { name: 'Negative signals', value: totalRiskSignals },
    { name: 'Positive signals', value: totalGrowthSignals },
    {
      name: 'Conversation Depth',
      value: avgMessageDepth?.toFixed(1),
      tooltip:
        'Median number of back and forth interactions including the initial response.',
    },
  ];

  return (
    <section className="flex flex-row gap-4">
      {parsedStats.map((item) => (
        <div
          key={item.name}
          className="flex basis-1/5 justify-between rounded-lg border border-zinc-200 bg-white p-4 shadow-sm hover:shadow"
        >
          <div className="flex flex-col justify-between">
            <span className="text-xs font-medium leading-tight text-zinc-500">
              {item.name}{' '}
              {item.tooltip && (
                <Tooltip content={item.tooltip}>
                  <FontAwesomeIcon
                    className="text-zinc-500 transition-colors hover:text-emerald-600"
                    icon={faCircleInfo}
                  />
                </Tooltip>
              )}
            </span>
            <span className="font-display text-2xl font-semibold leading-tight text-zinc-800">
              {item.value}
            </span>
          </div>
          {item?.showGauge && <Gauge value={+(item?.value || '0')} />}
        </div>
      ))}
    </section>
  );
}
