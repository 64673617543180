import { gql } from '../__generated__/gql';

export const UPDATE_INSIGHT = gql(/* GraphQL */ `
  mutation UpdateInsight($input: UpdateInsightInput!) {
    updateInsight(input: $input) {
      id
      followup
    }
  }
`);
