import { gql } from '../__generated__/gql';

export const LIST_UNIQUE_TAGS = gql(/* GraphQL */ `
  query ListUniqueTags {
    uniqueTags {
      name
      color
    }
  }
`);
