import { Message, MessageSender, Survey } from '@/survey-graphql';

export const DEFAULT_ACCENT_COLOR = '#ef4444';
export const SAMPLE_SURVEY: Survey = {
  primaryQuestion: 'How satisfied are you with contest frequency?',
  ratings: [
    { label: 'Negative', value: 1, __typename: 'Rating', id: '1' },
    { label: '', value: 2, __typename: 'Rating', id: '2' },
    { label: '', value: 3, __typename: 'Rating', id: '3' },
    { label: '', value: 4, __typename: 'Rating', id: '4' },
    { label: 'Positive', value: 5, __typename: 'Rating', id: '5' },
  ],
  isExpired: false,
  __typename: 'Survey',
  company: {
    name: 'Company',
    website: 'https://www.company.com',
    __typename: 'Company',
  },
  id: '1',
};
export const SAMPLE_MESSAGES: Message[] = [
  {
    createdAt: new Date(),
    id: '1',
    sender: MessageSender.Assistant,
    text: "That's great to hear! Could you share more about What you find most valuable in the insights provided by UserTesting?",
    content:
      "That's great to hear! Could you share more about What you find most valuable in the insights provided by UserTesting?",
    finished: true,
  },
  {
    createdAt: new Date(),
    id: '2',
    sender: MessageSender.User,
    text: "I like that it's easy to set up tests and that I get results back within an hour. This allows me to quickly analyze feedback and make informed decisions without delays.",
    content:
      "I like that it's easy to set up tests and that I get results back within an hour. This allows me to quickly analyze feedback and make informed decisions without delays.",
    finished: true,
  },
  {
    createdAt: new Date(),
    id: '3',
    sender: MessageSender.Assistant,
    text: "That's fantastic feedback! It's great to know that the quick turnaround and ease of setup are working well for you. Are there any features or tools you wish you had access to with UserTesting that could make your experience even better?",
    content:
      "That's fantastic feedback! It's great to know that the quick turnaround and ease of setup are working well for you. Are there any features or tools you wish you had access to with UserTesting that could make your experience even better?",
    finished: true,
  },
];

export async function uploadImageToStorage(url: string, file: File) {
  const res = await fetch(url, {
    method: 'PUT',
    body: file,
    headers: {
      'Content-Type': file.type,
    },
  });
  if (!res.ok) {
    throw new Error('Failed to upload image to storage');
  }
  return res.text();
}

export const FIVE_MEGABYTES = 5 * 1024 * 1024;
