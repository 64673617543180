import { MessageSender } from '@/survey-graphql';

export const OPTIMISTIC_MESSAGE_TEMP_SENT_ID = 'temp-sent-id';
export const OPTIMISTIC_MESSAGE_TEMP_REPLY_ID = 'temp-reply-id';
export const OPTIMISTIC_SENT_MESSAGE = {
  id: OPTIMISTIC_MESSAGE_TEMP_SENT_ID,
  content: '',
  createdAt: new Date(),
  sender: MessageSender.User,
  finished: false,
};
export const OPTIMISTIC_REPLY_MESSAGE = {
  id: OPTIMISTIC_MESSAGE_TEMP_REPLY_ID,
  content: '...',
  createdAt: new Date(),
  sender: MessageSender.Assistant,
  finished: false,
};
