import {
  faArrowRightFromBracket,
  faCreditCard,
  faKey,
  faPalette,
  faUserGear,
} from '@fortawesome/pro-light-svg-icons';
import { Content, Portal, Root, Trigger } from '@radix-ui/react-dropdown-menu';
import { useAuth } from '@workos-inc/authkit-react';

import { BillingPlanCode } from '@/manager-graphql';
import { useUpgrade, useUpgradeBilling } from '@/manager-upgrade';
import SidebarFooterMenuButtonItem from './manager-sidebar.footer-menu-button-item';
import SidebarFooterMenuItem from './manager-sidebar.footer-menu-item';
import { useSidebar, useSidebarData } from './manager-sidebar.hooks';

export default function SidebarFooterMenu() {
  const { sidebarState } = useSidebar();
  const { hasValidOrg } = useSidebarData();
  const { handleToggleUpgrade, planDetails, stripeCustomerId } = useUpgrade();
  const { user } = useAuth();
  const { handleCustomerPortalSession } = useUpgradeBilling();

  const handlePlanAndBilling = () => {
    if (
      planDetails?.planCode === BillingPlanCode.Free &&
      !stripeCustomerId?.trim()?.length
    ) {
      handleToggleUpgrade();
      return;
    } else {
      handleCustomerPortalSession();
    }
  };

  const name: string =
    user?.firstName || user?.lastName
      ? `${user?.firstName} ${user.lastName}`.trim()
      : user?.email || '';

  return (
    <div className="mx-4 border-t border-zinc-800 pb-6 pt-2">
      <Root>
        <Trigger
          aria-label="Settings"
          className="group relative w-full rounded-full text-left last:flex focus:outline-none"
        >
          {user?.profilePictureUrl ? (
            <img
              className="h-10 w-10 rounded-full"
              src={user.profilePictureUrl}
              alt="Avatar"
            />
          ) : (
            <div className="font-display flex h-10 w-10 items-center justify-center rounded-full bg-rose-100 text-xl uppercase text-red-700">
              {name
                .split(' ')
                .map((n) => n.charAt(0))
                .join('')}
            </div>
          )}

          <div
            data-state={sidebarState}
            className="ml-2 w-0 overflow-hidden group-hover/sidebar:w-full data-[state=fixed]:w-full"
          >
            <p className="truncate text-sm font-semibold leading-6 tracking-tight text-white">
              {name}
            </p>
            <p className="truncate text-xs font-normal leading-tight tracking-tight text-neutral-400">
              {user?.email}
            </p>
          </div>
        </Trigger>

        <Portal>
          <Content
            side="right"
            className="data-[side=right]:animate-slide-left-and-fade inline-flex min-w-64 flex-col items-center justify-start gap-1 rounded-xl bg-white p-1 shadow will-change-[opacity,transform]"
            sideOffset={32}
          >
            {hasValidOrg ? (
              <>
                <SidebarFooterMenuItem
                  icon={faUserGear}
                  text="User Management"
                  to="/user-management"
                />
                <SidebarFooterMenuButtonItem
                  icon={faCreditCard}
                  text="Plans & Billing"
                  onClick={handlePlanAndBilling}
                />
                <SidebarFooterMenuItem
                  icon={faPalette}
                  text="Branding"
                  to="/branding"
                />
                <SidebarFooterMenuItem
                  icon={faKey}
                  text="API Access"
                  to="/api-access"
                />
              </>
            ) : null}
            <SidebarFooterMenuItem
              icon={faArrowRightFromBracket}
              text="Log out"
              to="/logout"
            />
          </Content>
        </Portal>
      </Root>
    </div>
  );
}
