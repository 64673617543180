import { SurveyChat, SurveyPoweredBy, SurveyRatings } from '@/survey-response';
import { ComponentProps, useState } from 'react';
import ManagerBrandingPreviewSection from './manager-branding-preview-section';
import { SAMPLE_MESSAGES, SAMPLE_SURVEY } from './manager-branding.helpers';

export default function ManagerBrandingDesktopPreview({
  accentColor,
  avatarUrl,
  logoUrl,
  showPoweredBy,
}: ComponentProps<typeof ManagerBrandingPreviewSection>) {
  const [selectedRating, setSelectedRating] = useState<number | null>(2);

  return (
    <div className="animate-fade-in flex flex-col gap-0 rounded-xl bg-gray-50 p-6">
      <img
        src="/img/desktop-browser-header.svg"
        alt="Desktop browser header"
        className="h-9 w-full object-cover"
      />
      <div className="relative -mt-0.5 flex w-full flex-col items-center self-stretch rounded-lg bg-gray-100 px-16 py-6">
        {logoUrl ? (
          <img
            src={logoUrl}
            alt="Logo"
            className="absolute inset-8 h-14 w-auto"
          />
        ) : null}
        <SurveyRatings
          survey={SAMPLE_SURVEY}
          onSelect={(v) => setSelectedRating(v)}
          selectedValue={selectedRating}
          accentColor={accentColor}
          className="origin-top scale-75 self-stretch"
        />
        <SurveyChat
          accentColor={accentColor}
          avatarUrl={avatarUrl}
          className="-mt-4 h-[28rem] origin-top scale-75 self-stretch"
          messages={SAMPLE_MESSAGES}
          scrollToEnd={false}
          onSubmit={Promise.resolve}
        />

        <div
          data-hidden={!showPoweredBy}
          className="-mt-24 data-[hidden=true]:invisible"
        >
          <SurveyPoweredBy />
        </div>
      </div>
    </div>
  );
}
